import * as SDK from '@replai-platform/sdk';
import * as UI from '@replai-platform/ui-components';
import { MARKET_LOCKED_FEATURE_NAME } from '../../configurations/market';
import { Page } from '../../utils/enums';
import { logEvent } from '../../analytics';

export type NavigationBarConfig = {
  currentPage: Page;
  enabledProductUpdates: SDK.ProductUpdateDetails[];
  featureFlags: {
    isTechMerc1388NotificationEnabled: boolean;
    isTechMerc1388Enabled: boolean;
    isProdrm48Enabled: boolean;
    isTechMerc1175Enabled: boolean;
    isTechAth1346Enabled: boolean;
    isTechMerc1547Enabled: boolean;
    isTechMerc1679Enabled: boolean;
  };
  projectId: string;
  projectMarketGenres: string[];
  productUpdatesItemLabel: string;
  productUpdatesSidebarOpen: boolean;
  setProductUpdatesSidebarOpen: (open: boolean) => void;
  accountTypes?: SDK.AccountTypes[];
};

const bottomConfig = ({
  currentPage,
  enabledProductUpdates,
  projectId,
  productUpdatesItemLabel,
  productUpdatesSidebarOpen,
  setProductUpdatesSidebarOpen,
}: NavigationBarConfig) => [
  {
    icon: 'Bell' as UI.Icons.BaseIconTypes,
    label: productUpdatesItemLabel,
    currentPage,
    hasNotification: enabledProductUpdates.some((productUpdate) => !productUpdate.read),
    projectId,
    onClick: () => {
      logEvent({
        action: `${productUpdatesSidebarOpen ? 'Hide' : 'Show'} product updates sidebar`,
        category: 'user_actions',
        component: 'Menu',
      });
      setProductUpdatesSidebarOpen(!productUpdatesSidebarOpen);
    },
  },
  {
    icon: 'Settings' as UI.Icons.BaseIconTypes,
    label: 'Settings',
    currentPage,
    targetPage: Page.Settings,
    projectId,
  },
];

const mainConfig = ({
  currentPage,
  featureFlags,
  projectId,
  projectMarketGenres,
  accountTypes,
}: NavigationBarConfig) => [
  {
    icon: 'Grid' as UI.Icons.BaseIconTypes,
    label: 'Overview',
    currentPage,
    targetPage: Page.Dashboard,
    projectId,
  },
  {
    icon: 'BookOpen' as UI.Icons.BaseIconTypes,
    label: 'Library',
    currentPage,
    projectId,
    subMenu: [
      {
        label: 'Tags',
        icon: 'Tag' as UI.Icons.BaseIconTypes,
        targetPage: Page.TagsLibrary,
      },
      {
        label: 'Concepts',
        icon: 'Film' as UI.Icons.BaseIconTypes,
        targetPage: Page.ConceptsLibrary,
      },
    ],
  },
  ...(featureFlags.isTechMerc1547Enabled
    ? [
        {
          icon: 'BarLineChart' as UI.Icons.BaseIconTypes,
          label: 'Metrics',
          currentPage,
          targetPage: Page.Performance,
          projectId,
        },
      ]
    : []),
  ...((!accountTypes || accountTypes.includes(SDK.AccountTypes.ADS)) && !featureFlags.isTechMerc1547Enabled
    ? [
        {
          icon: 'Video' as UI.Icons.BaseIconTypes,
          label: 'Videos',
          currentPage,
          targetPage: Page.Videos,
          projectId,
        },
      ]
    : []),
  ...(accountTypes && accountTypes.includes(SDK.AccountTypes.SOCIALS)
    ? [
        {
          icon: 'Activity' as UI.Icons.BaseIconTypes,
          label: 'Posts',
          currentPage,
          targetPage: Page.SocialPosts,
          projectId,
          hasNotification: true,
        },
      ]
    : []),
  {
    icon: 'Tag' as UI.Icons.BaseIconTypes,
    label: 'Tags',
    currentPage,
    projectId,
    subMenu: [
      {
        label: 'Performance',
        icon: 'BarLineChart' as UI.Icons.BaseIconTypes,
        targetPage: Page.Tags,
      },
      {
        label: 'Combinations',
        icon: 'CheckDone' as UI.Icons.BaseIconTypes,
        targetPage: Page.Combinations,
      },
      {
        label: 'Insights',
        icon: 'FolderSearch' as UI.Icons.BaseIconTypes,
        targetPage: Page.Insights,
      },
    ],
  },
  ...(featureFlags.isTechMerc1175Enabled && !featureFlags.isTechMerc1547Enabled
    ? [
        {
          icon: 'Image' as UI.Icons.BaseIconTypes,
          label: 'Statics',
          currentPage,
          projectId,
          subMenu: [
            {
              label: 'Performance',
              icon: 'BarLineChart' as UI.Icons.BaseIconTypes,
              isBeta: true,
              targetPage: Page.StaticsPerformance,
            },
          ],
        },
      ]
    : []),
  ...(accountTypes && accountTypes.includes(SDK.AccountTypes.SOCIALS)
    ? []
    : [
        {
          icon: 'Globe' as UI.Icons.BaseIconTypes,
          label: 'Market',
          currentPage,
          projectId,
          subMenu: [
            {
              label: 'Tag Gallery',
              icon: 'ClapperBoard' as UI.Icons.BaseIconTypes,
              lockedConfig: projectMarketGenres?.length
                ? undefined
                : {
                    featureName: MARKET_LOCKED_FEATURE_NAME,
                    title: 'Market Insights',
                    subtitle: 'Want to know where you stand against your competitors?',
                  },
              isBeta: true,
              targetPage: Page.MarketTagsLibrary,
            },
            {
              label: 'Creative Gallery',
              icon: 'PuzzlePiece' as UI.Icons.BaseIconTypes,
              lockedConfig: projectMarketGenres?.length
                ? undefined
                : {
                    featureName: MARKET_LOCKED_FEATURE_NAME,
                    title: 'Market Insights',
                    subtitle: 'Want to know where you stand against your competitors?',
                  },
              isBeta: true,
              targetPage: Page.MarketVideosLibrary,
            },
          ],
        },
      ]),
  ...(featureFlags.isTechMerc1388Enabled
    ? [
        {
          icon: 'Sparkles' as UI.Icons.BaseIconTypes,
          label: 'Generate',
          currentPage,
          targetPage: Page.CreativeProduction,
          projectId,
          hasNotification: featureFlags.isTechMerc1388NotificationEnabled,
        },
      ]
    : []),
  ...(!featureFlags.isTechMerc1388Enabled && featureFlags.isProdrm48Enabled
    ? [
        {
          icon: 'PenTool' as UI.Icons.BaseIconTypes,
          label: 'Inspire',
          currentPage,
          targetPage: Page.Inspire,
          projectId,
        },
      ]
    : []),
  ...(featureFlags.isTechAth1346Enabled
    ? [
        {
          icon: 'FileText' as UI.Icons.BaseIconTypes,
          label: 'Reports',
          currentPage,
          targetPage: Page.Reports,
          projectId,
        },
      ]
    : []),
  ...(featureFlags.isTechMerc1679Enabled
    ? [
        {
          icon: 'Upload' as UI.Icons.BaseIconTypes,
          label: 'Upload',
          currentPage,
          targetPage: Page.UploadVideos,
          projectId,
        },
      ]
    : []),
];

export default {
  bottom: bottomConfig,
  main: mainConfig,
};
