import * as SDK from '@replai-platform/sdk';

export enum Page {
  Videos = 'videos',
  Concepts = 'concepts',
  ConceptsLibrary = 'concepts-library',
  CreativeProduction = 'creative-production',
  Dashboard = 'dashboard',
  ComparePage = 'compare',
  VideoView = 'video-view',
  Tags = 'tags',
  TagsLibrary = 'tags-library',
  Insights = 'insights',
  Combinations = 'combinations',
  Settings = 'settings',
  IterationView = 'iteration-view',
  MarketTagsLibrary = 'market-tags-library',
  MarketVideosLibrary = 'market-videos-library',
  SignIn = 'sign-in',
  StaticsPerformance = 'statics',
  StaticView = 'static',
  Inspire = 'inspire',
  Reports = 'reports',
  SocialPosts = 'socials-performance',
  SocialPostView = 'socials-post-view',
  Performance = 'performance',
  PlayableView = 'playable',
  ReplaiCreativesPerformance = 'replai-creatives',
  UploadVideos = 'upload-videos',
}

// Don't change the order, it's important for the SlideoutMenu component
export enum Filters {
  Spend = 'spend',
  Installs = 'installs',
  Campaigns = 'campaign',
  CustomTag = 'customTag',
  Countries = 'country',
  OS = 'os',
  LaunchedDate = 'launchedDate',
  MarketNetwork = 'marketNetwork',
}

export enum ActivityType {
  all = 'all',
  active = 'active',
  inactive = 'inactive',
}

/**
 * Every time you add a new column here and you want to add it to one or more performance tables
 * you should specify on which tables here apps/frontend/src/utils/tables.ts
 */
export enum Columns {
  Name = 'name',
  Preview = 'preview',
  CompositePreview = 'compositePreview',
  SpendTrend = 'spendTrend',
  Spend = 'metrics.spend',
  IPM = 'metrics.ipm',
  ROAS = 'metrics.roas',
  AGE = 'ads.age',
  Impressions = 'metrics.impressions',
  Views = 'metrics.views',
  Installs = 'metrics.installs',
  Clicks = 'metrics.clicks',
  Purchases = 'metrics.purchases',
  PurchasesDay7 = 'metrics.purchasesDay7',
  Earnings = 'metrics.earnings',
  EarningsDay7 = 'metrics.earningsDay7',
  Comments = 'metrics.comments',
  SubscribersGained = 'metrics.subscribersGained',
  SubscribersLost = 'metrics.subscribersLost',
  MinutesWatched = 'metrics.minutesWatched',
  Likes = 'metrics.likes',
  Dislikes = 'metrics.dislikes',
  AvgViewDuration = 'metrics.avgViewDuration',
  Shares = 'metrics.shares',
  EngagementRate = 'metrics.engagementRate',
  User = 'user',
  Status = 'status',
  Actions = 'actions',
  ReplaiScore = 'replaiScore',
  AppearsIn = 'appearsIn',
  LifetimeSpend = 'lifetimeSpend',
  Suggestions = 'suggestions',
  RecommendationsName = 'recommendationsName',
  Frequency = 'frequency',
  AdInstances = 'adInstances',
  NumCreatives = 'numCreatives',
  UserEmail = 'userEmail',
  CreatedAt = 'createdAt',
  ReportStatus = 'reportStatus',
  ReportName = 'reportName',
  ReportType = 'reportType',
  ReportOpen = 'reportOpen',
  ReportFavorite = 'reportFavorite',
  ReportSaveTemplate = 'reportSaveTemplate',
  ReportApplyTemplate = 'reportApplyTemplate',
  ReportDelete = 'reportDelete',
  ReportDeleteTemplate = 'reportDeleteTemplate',
}

export function metricToColumnEnum(metric: SDK.Metrics) {
  for (const column in Columns) {
    if (Columns[column] === `metrics.${metric}`) {
      return column;
    }
  }
  return null;
}

export enum QueryParamFilter {
  campaignId = 'campaignId',
  network = 'network',
  promotedObjectType = 'promotedObjectType',
  country = 'country',
}
