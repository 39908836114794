import { Network, PromotedObjectType } from '../ads/enums';
import { UUID } from '../atomics';
import { ParsedAnnotation } from '../common';
import {
  MetricKPIAvg,
  MetricOrKpi,
  Metrics,
  MetricTotal,
} from '../metrics/enums';

export enum AssetType {
  Video = 'video',
  Image = 'image',
  Playable = 'playable',
}

/**
 * Filters applied to asset queries.
 */
export type AssetFilters = {
  types?: AssetType[];
  taggedGenres?: string[];
  minSpend?: number;
  minInstalls?: number;
  minImpressions?: number;
  minActiveDate?: number; // If specified, asset must have metrics at least up to this timestamp.
  maxActiveDate?: number; // If specified, asset must have metrics at max up to this timestamp.
  taggedWith?: UUID[];
  name?: {
    contains: string;
    ignoreCase: boolean;
  };
};

export type AssetMetrics = {
  assetCount: number;
  adsCount: number;
  id: UUID;
  name: string;
  assetNetwork: Network;
  assetNetworkAssetId: string;
  assetType: AssetType;
  assetAnnotation: ParsedAnnotation[];
  maxDate: string;
  spend: number;
  impressions: number;
  installs: number;
  network?: Network[];
  os?: PromotedObjectType[];
  width?: number;
  height?: number;
  fatiguing?: boolean;
} & Partial<Record<MetricOrKpi, number>> &
  Partial<Record<MetricKPIAvg, number>> &
  Partial<Record<MetricTotal, number>> &
  Partial<Record<Metrics.AGE, string>>;

/** Pairs of the percentile of the video and the audience retention for that period. */
export type AssetRetentionPeriod = [number, number];

export type AssetRetentionInfo = {
  periods: AssetRetentionPeriod[];
};

export type AssetDayOfWeekPerformance<MetricsType> = {
  /** 0 is Sunday as per Postgres definition: https://www.postgresql.org/docs/current/functions-datetime.html */
  dayOfWeek: number;
  metrics: MetricsType;
};

export type AssetMetricsWithTags = {
  id: UUID;
  name: string;
  duration: number;
  network: Network;
  networkAssetId: string;
  assetType: AssetType;
  annotation: ParsedAnnotation[];
  coreAnnotation: ParsedAnnotation[];
  postQaAnnotation: ParsedAnnotation[];
  spend: number;
  impressions: number;
  installs: number;
  url: string;
  appName: string;
  tags: { id: UUID; value: string; type: string; kind: string }[];
} & Partial<Record<MetricOrKpi, number>> &
  Partial<Record<MetricKPIAvg, number>> &
  Partial<Record<MetricTotal, number>> &
  Partial<Record<Metrics.AGE, string>>;

export type AssetAge = {
  id: UUID;
  age: string;
};
