import { useFeature } from '@optimizely/react-sdk';
import { AnimatePresence } from 'framer-motion';
import * as SDK from '@replai-platform/sdk';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ArrayParam, QueryParamProvider, useQueryParam } from 'use-query-params';
import LayoutContainer from '../components/LayoutContainer';
import MainPanelLayout from '../components/MainPanelLayout';
import { AppActions } from '../store/app';
import { RootState } from '../store/rootReducer';
import { Page, QueryParamFilter } from '../utils/enums';
import { Route404 } from './404';
import Combinations from './Combinations';
import CompareView from './Compare';
import ConceptsView from './Concepts';
import PerformanceView from './Performance';
import ReplaiPerformanceView from './ReplaiPerformance';
import ConceptView from './Concepts/ConceptView';
import ConceptsLibrary from './Concepts/Library';
import CreativeView from './Concepts/CreativeView';
import Dashboard from './Dashboard';
import { ErrorBoundary } from './ErrorBoundary';
import Insights from './Insights';
import Inspire from './Inspire';
import CreativeProduction from './CreativeProduction';
import LoginRoute from './Login';
import MarketTagsLibrary from './Market/TagsLibrary';
import MarketVideosLibrary from './Market/VideosLibrary';
import RequireAuth from './RequireAuth';
import TagsView from './Tags';
import TagsLibrary from './Tags/Library';
import TagView from './Tags/TagView';
import Settings from './Settings';
import SocialsPerformance from './Social/SocialsPerformance';
import StaticsPerformance from './Statics/Performance';
import StaticView from './Statics/StaticView';
import Reports from './Reports';
import ReportsGoogleSlides from './Reports/GoogleSlides';
import ReportsGoogleSheets from './Reports/GoogleSheets';
import UploadVideos from './UploadVideos';
import { FilterActions } from '../store/filters';

const InsideQueryParamHandler: React.VFC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isProdrm48Enabled] = useFeature('prodrm-48'); // inspire
  const [isTechmerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production
  const [isTechMerc1175Enabled] = useFeature('techmerc-1175'); // statics performance
  const [isTechAth1346Enabled] = useFeature('techath-1346'); // reports page
  const [isTechmerc1480Enabled] = useFeature('techmerc-1480'); // socials features
  const [isTechMerc1547Enabled] = useFeature('techmerc-1547'); // performance page
  const [isTechMerc1589Enabled] = useFeature('techmerc-1589'); // Replai creatives performance
  const [isTechMerc1575Enabled] = useFeature('techmerc-1575'); // Google Sheets export
  const [isTechMerc1679Enabled] = useFeature('techmerc-1679'); // Google Sheets export

  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);
  const organizationName = useSelector((state: RootState) => state.project.organizationName);

  const [campaignIdsQueryParam, setCampaignIdsQueryParam] = useQueryParam(QueryParamFilter.campaignId, ArrayParam);
  if (campaignIdsQueryParam?.length) {
    dispatch(
      FilterActions.changeCampaigns({
        logEvent: false,
        toExclude: false,
        value: campaignIdsQueryParam.filter((c): c is string => typeof c === 'string'),
      })
    );
    setCampaignIdsQueryParam([]);
  }

  const [promotedObjectTypesQueryParam, setPromotedObjectTypesQueryParam] = useQueryParam(
    QueryParamFilter.promotedObjectType,
    ArrayParam
  );
  if (promotedObjectTypesQueryParam?.length) {
    dispatch(
      FilterActions.changePromotedObjectTypesToConsider({
        logEvent: true,
        value: promotedObjectTypesQueryParam as SDK.PromotedObjectType[],
      })
    );
    setPromotedObjectTypesQueryParam([]);
  }

  const [countriesQueryParam, setCountriesQueryParam] = useQueryParam(QueryParamFilter.country, ArrayParam);
  if (countriesQueryParam?.length) {
    dispatch(
      FilterActions.changeCountries({
        logEvent: false,
        value: countriesQueryParam.filter((c): c is string => typeof c === 'string'),
      })
    );
    setCountriesQueryParam([]);
  }

  // Keep global 'page' state updated.
  useEffect(() => {
    const pathSegments = pathname?.split('/') || [];
    const pathMainPage = pathSegments?.length > 2 ? pathSegments[2] : '';
    let pageEnumValue: Page | undefined;
    for (const supportedPage of Object.keys(Page)) {
      if (Page[supportedPage] === pathMainPage) {
        pageEnumValue = pathMainPage as Page;
      }
    }
    if (pageEnumValue) {
      dispatch(AppActions.setPage(pageEnumValue));
    }

    // Scrolls to the top of the container when the route changes
    window.document.getElementById('content-container')?.scrollTo({ top: 0 });
  }, [dispatch, pathname]);

  return (
    <AnimatePresence exitBeforeEnter>
      <ErrorBoundary>
        <Routes>
          <Route
            path={`/${Page.VideoView}/:videoId/*`}
            element={<CreativeView currentPage={Page.VideoView} assetType={SDK.AssetType.Video} />}
          />
          <Route path={`/${Page.Concepts}/:conceptId/*`} element={<ConceptView />} />
          <Route
            path={`/${Page.PlayableView}/:assetId/*`}
            element={<CreativeView currentPage={Page.PlayableView} assetType={SDK.AssetType.Playable} />}
          />
          <Route path={`/${Page.Dashboard}`} element={<Dashboard />} />
          <Route path={`/${Page.Videos}`} element={<ConceptsView />} />
          <Route path={`/${Page.Tags}/:tagId/*`} element={<TagView />} />
          <Route path={`/${Page.Tags}`} element={<TagsView />} />
          <Route path={`/${Page.Insights}/*`} element={<Insights />} />
          <Route path={`/${Page.Combinations}`} element={<Combinations />} />
          <Route path={`/${Page.Settings}`} element={<Settings />} />
          <Route path={`/${Page.TagsLibrary}`} element={<TagsLibrary />} />
          <Route path={`/${Page.ConceptsLibrary}`} element={<ConceptsLibrary />} />
          {isTechMerc1547Enabled && <Route path={`/${Page.Performance}`} element={<PerformanceView />} />}
          {isTechMerc1589Enabled && (
            <Route
              path={`/${Page.ReplaiCreativesPerformance}/${organizationName.replaceAll(' ', '').toLowerCase()}`}
              element={<ReplaiPerformanceView />}
            />
          )}
          {isTechmerc1388Enabled && <Route path={`/${Page.CreativeProduction}`} element={<CreativeProduction />} />}
          {!isTechmerc1388Enabled && isProdrm48Enabled && <Route path={`/${Page.Inspire}`} element={<Inspire />} />}
          {isTechmerc1480Enabled && <Route path={`/${Page.SocialPosts}`} element={<SocialsPerformance />} />}
          {isTechmerc1480Enabled && (
            <Route
              path={`/${Page.SocialPostView}/:videoId/*`}
              element={<CreativeView currentPage={Page.SocialPostView} assetType={SDK.AssetType.Video} />}
            />
          )}
          <Route path="/market" element={<Navigate to={`../${Page.MarketTagsLibrary}`} />} />
          {projectMarketGenres?.length ? (
            <Route path={`/${Page.MarketTagsLibrary}`} element={<MarketTagsLibrary />} />
          ) : (
            ''
          )}
          {isTechMerc1175Enabled ? <Route path={`/${Page.StaticsPerformance}`} element={<StaticsPerformance />} /> : ''}
          {isTechMerc1175Enabled ? <Route path={`/${Page.StaticView}/:videoId/*`} element={<StaticView />} /> : ''}
          {projectMarketGenres?.length ? (
            <Route path={`/${Page.MarketVideosLibrary}`} element={<MarketVideosLibrary />} />
          ) : (
            ''
          )}
          <Route path={`/${Page.ComparePage}`} element={<CompareView />} />
          {isTechAth1346Enabled && <Route path={`/${Page.Reports}`} element={<Reports />} />}
          {isTechAth1346Enabled && <Route path={`/${Page.Reports}/google-slides`} element={<ReportsGoogleSlides />} />}
          {isTechMerc1575Enabled && <Route path={`/${Page.Reports}/google-sheets`} element={<ReportsGoogleSheets />} />}
          {isTechMerc1679Enabled && <Route path={`/${Page.UploadVideos}`} element={<UploadVideos />} />}

          {/* Redirect root directory to the Dashboard */}
          <Route path="/" element={<Navigate to={`${Page.Dashboard}`} />} />
          <Route path="*" element={<Route404 />} />
        </Routes>
      </ErrorBoundary>
    </AnimatePresence>
  );
};

const ComponentRenderer = (props: any) => {
  const page = useSelector((state: RootState) => state.app.page);

  // eslint-disable-next-line react/jsx-props-no-spreading
  const renderQueryParamHandler = () => <InsideQueryParamHandler {...props} />;

  return (
    <LayoutContainer>
      {[Page.TagsLibrary, Page.MarketTagsLibrary, Page.ComparePage, Page.Insights].includes(page) ? (
        renderQueryParamHandler()
      ) : (
        <MainPanelLayout>{renderQueryParamHandler()}</MainPanelLayout>
      )}
    </LayoutContainer>
  );
};

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return children({ history: adaptedHistory, location: currentLocation });
};

const AppRoutes = () => (
  <QueryParamProvider ReactRouterRoute={RouteAdapter}>
    <Routes>
      <Route path="/sign-in" element={<LoginRoute />} />
      <Route
        path={'/:project/*'}
        element={
          <RequireAuth>
            <ComponentRenderer />
          </RequireAuth>
        }
      />
      <Route index element={<Navigate replace to="/sign-in" />} />
    </Routes>
  </QueryParamProvider>
);

export default AppRoutes;
