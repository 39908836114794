import * as SDK from '@replai-platform/sdk';
import { useMutation } from 'react-query';
import * as provider from '../../api/provider';

async function uploadVideos(projectId: SDK.UUID, body: SDK.UploadVideosRequestBody) {
  const url = `${SDK.BASE_URL()}/upload-videos/${projectId}`;
  return provider.getJson<object, { id: string }>(url, { method: 'POST', body });
}

export default function useUploadVideos(projectId: SDK.UUID) {
  return useMutation([`upload-videos`], (body: SDK.UploadVideosRequestBody) => uploadVideos(projectId, body));
}
