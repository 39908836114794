import { useFeature } from '@optimizely/react-sdk';
import * as SDK from '@replai-platform/sdk';
import * as UI from '@replai-platform/ui-components';
import { camelCaseToCapitalCase, LockedConfig } from '@replai-platform/ui-components';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { logEvent } from '../../analytics';
import { api } from '../../api';
import useProjects from '../../api/hooks/projects/useProjects';
import { AuthActions } from '../../store/auth';
import { ProjectActions } from '../../store/project';
import { RootState } from '../../store/rootReducer';
import { Page } from '../../utils/enums';
import OptimizelyInstance from '../../utils/optimizely';
import LockedNavigationItemModal from './LockedNavigationItemModal';
import ProductUpdatesSidebar from '../ProductUpdatesSidebar';
import useProductUpdates from '../../api/hooks/productUpdates/useProductUpdates';
import navigationConfig from './config';

type GetNavigationItem = Omit<UI.NavigationItemProps, 'subMenu'> & {
  currentPage: Page;
  lockedConfig?: LockedConfig;
  key: string;
  projectId: SDK.UUID;
  targetPage?: Page;
  setFeatureModalOpenStatus: (args: { key: string; open: boolean }) => void;
  subMenu?: (UI.NavigationSubItemProps & { key: string; lockedConfig?: LockedConfig; targetPage?: Page })[];
};

const PRODUCT_UPDATES_ITEM_LABEL = 'Updates';

const logMenuClickUnlockedEvent = (page: Page | 'Replai Logo') =>
  logEvent({
    component: 'Menu',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    action: `Click on ${camelCaseToCapitalCase(page.toString())}`,
    category: 'user_actions',
  });

const logMenuClickLockedEvent = (page: Page | 'Replai Logo') =>
  logEvent({
    component: 'Menu',
    action: `Click on locked ${camelCaseToCapitalCase(page.toString())}`,
    category: 'user_actions',
  });

const navigationItemLabelToId = (label: string) => `nav-item-${label.toLowerCase().replace(/ /, '-')}`;

const navigationItem = ({
  icon,
  label,
  currentPage,
  hasNotification,
  onClick,
  targetPage,
  projectId,
  setFeatureModalOpenStatus,
  subMenu,
}: GetNavigationItem) => ({
  icon,
  id: navigationItemLabelToId(label),
  label,
  isSelected: currentPage === targetPage,
  href: targetPage ? `/${projectId}/${targetPage ?? ''}` : undefined,
  hasNotification,
  onClick:
    targetPage || onClick
      ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          if (targetPage) {
            logMenuClickUnlockedEvent(targetPage);
          }
          onClick?.(e);
        }
      : undefined,
  subMenu: subMenu
    ? subMenu.map(({ targetPage: subMenuTargetPage, ...subMenuItem }) => ({
        ...subMenuItem,
        isSelected: currentPage === subMenuTargetPage,
        href: `/${projectId}/${subMenuTargetPage ?? ''}`,
        onClick: subMenuTargetPage
          ? () => {
              if (subMenuItem.lockedConfig) {
                logMenuClickLockedEvent(subMenuTargetPage);
                setFeatureModalOpenStatus({
                  key: subMenuItem.key,
                  open: true,
                });
              } else {
                logMenuClickUnlockedEvent(subMenuTargetPage);
              }
            }
          : undefined,
      }))
    : undefined,
});

const NavigationProductUpdatesSidebarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavigationBar = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const projectName = useSelector((state: RootState) => state.project.name);
  const projectIcon = useSelector((state: RootState) => state.project.icon);
  const accountTypes = useSelector((state: RootState) => state.project.config.accountTypes);
  const currentPage = useSelector((state: RootState) => state.app.page);
  const userId = useSelector((state: RootState) => state.auth.id);
  const [userEmail, setUserEmail] = useState('');
  const [productUpdatesSidebarOpen, setProductUpdatesSidebarOpen] = useState(false);
  const [openLockedFeatureModals, setOpenLockedFeatureModals] = useState(new Set<string>());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isProdrm48Enabled] = useFeature('prodrm-48'); // inspire
  const [isTechMerc1175Enabled] = useFeature('techmerc-1175'); // statics performance
  const [isTechMerc1388Enabled] = useFeature('techmerc-1388'); // Creative Production
  const [isTechMerc1388NotificationEnabled] = useFeature('techmerc-1388-notification'); // Creative Production Notification Enable
  const [isTechMerc1547Enabled] = useFeature('techmerc-1547'); // Videos+Statics performance page
  const [isTechAth1346Enabled] = useFeature('techath-1346'); // reports page
  const [isTechMerc1679Enabled] = useFeature('techmerc-1679'); // Upload Videos Page

  const projectMarketGenres = useSelector((state: RootState) => state.project.config.marketGenres);

  const { data: productUpdatesResponse, isLoading: isLoadingProductUpdates } = useProductUpdates({});
  const enabledFeatures = useMemo(
    () => OptimizelyInstance.getEnabledFeatures(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(OptimizelyInstance.getEnabledFeatures())]
  );
  const enabledProductUpdates = useMemo(
    () =>
      (productUpdatesResponse?.productUpdates ?? []).filter(
        (productUpdate) => !productUpdate.featureFlag || enabledFeatures.includes(productUpdate.featureFlag)
      ),
    [enabledFeatures, productUpdatesResponse]
  );

  const setFeatureModalOpenStatus = useCallback(
    ({ key, open }: { key: string; open: boolean }) => {
      const modifiedSet = new Set(openLockedFeatureModals);
      if (open) {
        modifiedSet.add(key);
      } else {
        modifiedSet.delete(key);
      }
      setOpenLockedFeatureModals(modifiedSet);
    },
    [openLockedFeatureModals]
  );

  const enrichNavigationItemsConfig = useCallback(
    (
      config: (Omit<GetNavigationItem, 'key' | 'setFeatureModalOpenStatus' | 'subMenu'> & {
        subMenu?: Omit<Exclude<GetNavigationItem['subMenu'], undefined>[number], 'key'>[];
      })[]
    ): GetNavigationItem[] =>
      config.map((item, i) => ({
        ...item,
        key: i.toString(),
        setFeatureModalOpenStatus,
        subMenu: item.subMenu
          ? item.subMenu.map(
              (
                subItem: {
                  label: string;
                },
                j: number
              ) => ({
                ...subItem,
                setFeatureModalOpenStatus,
                key: `${i}.${j}`,
              })
            )
          : undefined,
      })),
    [setFeatureModalOpenStatus]
  );

  const { navigationItems, navigationItemsConfig, bottomNavigationItems, bottomNavigationItemsConfig } = useMemo(() => {
    const navigationBarConfig = {
      currentPage,
      enabledProductUpdates,
      featureFlags: {
        isTechMerc1388NotificationEnabled,
        isTechMerc1388Enabled,
        isProdrm48Enabled,
        isTechMerc1175Enabled,
        isTechAth1346Enabled,
        isTechMerc1547Enabled,
        isTechMerc1679Enabled,
      },
      projectId,
      projectMarketGenres: projectMarketGenres ?? [],
      productUpdatesItemLabel: PRODUCT_UPDATES_ITEM_LABEL,
      productUpdatesSidebarOpen,
      setProductUpdatesSidebarOpen,
      accountTypes,
    };
    const navigationItemsConfigInner = enrichNavigationItemsConfig(navigationConfig.main(navigationBarConfig));
    const bottomNavigationItemsConfigInner = enrichNavigationItemsConfig(navigationConfig.bottom(navigationBarConfig));
    return {
      bottomNavigationItems: bottomNavigationItemsConfigInner.map((item) => navigationItem(item)),
      bottomNavigationItemsConfig: bottomNavigationItemsConfigInner,
      navigationItems: navigationItemsConfigInner.map((item) => navigationItem(item)),
      navigationItemsConfig: navigationItemsConfigInner,
    };
  }, [
    currentPage,
    enabledProductUpdates,
    enrichNavigationItemsConfig,
    isTechMerc1388Enabled,
    isTechMerc1388NotificationEnabled,
    isProdrm48Enabled,
    isTechMerc1175Enabled,
    isTechAth1346Enabled,
    isTechMerc1547Enabled,
    productUpdatesSidebarOpen,
    projectId,
    projectMarketGenres,
    accountTypes,
  ]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user: { attributes: { email: string } }) => {
        setUserEmail(user.attributes.email);
        mixpanel.people.set({ $email: user.attributes.email, $name: userId });
        if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
          mixpanel.identify(userId);
          mixpanel.people.set(`Project - ${projectName}`, projectId);
        }
      })
      .catch((err) => {
        Sentry.captureEvent({
          message: 'User is not authenticated',
          level: 'error',
          extra: {
            err: err as Record<string, unknown>,
          },
        });
      });
  }, [projectId, userId, projectName, userEmail]);

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const onLogoutClose = useMemo(
    () => (reason: 'cancel' | 'confirm') => {
      if (reason === 'confirm') {
        logEvent({
          component: 'Authentication',
          action: 'User Sign out',
          category: 'user_actions',
        });
        api.auth
          .signOut()
          .then(() => {
            dispatch(AuthActions.clearUser());
            dispatch(ProjectActions.changeProjectData({ id: '' }));
            navigate(Page.SignIn);
          })
          .catch((err) => {
            Sentry.captureEvent({
              message: 'Sign out failed',
              level: 'error',
              extra: {
                err: err as Record<string, unknown>,
              },
            });
          });
      }
      setLogoutDialogOpen(false);
    },
    [dispatch, navigate]
  );

  const selectProject = ({
    targetProjectId,
    targetOrganizationId,
  }: {
    targetProjectId: SDK.UUID;
    targetOrganizationId: SDK.UUID;
  }) => {
    // We need to set the project id for Optimizely before dispatching the project to the store, otherwise, Optimizely
    // would derive which features should be toggled on based on the last project id that was on the store.
    OptimizelyInstance.setUser({
      id: userId,
      attributes: {
        USER_ID: userId,
        PROJECT_ID: targetProjectId,
        PROJECT_ORGANIZATION_ID: targetOrganizationId,
      },
    });

    navigate(`/${targetProjectId}`);
  };

  const { data: projects } = useProjects({
    select: (res) =>
      res.map(
        ({ id, icon, name, organizationId }: SDK.Project): UI.DropDownMenuOption => ({
          avatarUrl: icon,
          label: name,
          type: 'option',
          onClick: () => selectProject({ targetProjectId: id, targetOrganizationId: organizationId ?? '' }),
        })
      ),
  });

  return (
    <>
      <NavigationProductUpdatesSidebarWrapper>
        <UI.Navigation
          topAvatar={{ label: projectName, imgUrl: projectIcon }}
          topAvatarOptions={projects ?? []}
          bottomAvatar={{ label: userEmail, alt: userEmail }}
          topItems={navigationItems}
          bottomItems={bottomNavigationItems}
          onLogOut={() => setLogoutDialogOpen(true)}
          logo={{
            href: `/${projectId}/${Page.Dashboard}`,
            onClick: () => logMenuClickUnlockedEvent('Replai Logo'),
          }}
        />
        <div>
          <ProductUpdatesSidebar
            data={enabledProductUpdates}
            loading={isLoadingProductUpdates}
            open={productUpdatesSidebarOpen}
            onClose={(e) => {
              if (
                productUpdatesSidebarOpen &&
                !document
                  .getElementById(navigationItemLabelToId(PRODUCT_UPDATES_ITEM_LABEL))
                  ?.contains(e.target as Node)
              ) {
                logEvent({
                  action: 'Hide product updates sidebar',
                  category: 'user_actions',
                  component: 'Menu',
                });
                setProductUpdatesSidebarOpen(false);
              }
            }}
          />
        </div>
      </NavigationProductUpdatesSidebarWrapper>
      <UI.Logout isOpen={logoutDialogOpen} onClose={onLogoutClose} />{' '}
      {[
        ...new Set(
          [...(navigationItemsConfig ?? []), ...(bottomNavigationItemsConfig ?? [])]
            .flatMap((item) => [item, ...(item.subMenu || [])])
            .flat()
            .filter((item): item is typeof item & { lockedConfig: LockedConfig } => !!item.lockedConfig)
        ),
      ].map(({ lockedConfig, key }) => (
        <LockedNavigationItemModal
          content={{
            title: lockedConfig.title,
            subtitle: lockedConfig.subtitle,
          }}
          featureName={lockedConfig.featureName}
          isOpen={openLockedFeatureModals.has(key)}
          key={key}
          onClose={() => setFeatureModalOpenStatus({ key, open: false })}
        />
      ))}
    </>
  );
};

export default NavigationBar;
