/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  Button,
  Input,
  Typography,
  Colors,
  VideoCard,
  SectionHeader,
  Notification,
  NotificationProps,
} from '@replai-platform/ui-components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import TopNavPageTitle from '../../components/TopNavPageTitle';
import { RootState } from '../../store/rootReducer';
import useUploadVideosRequests from '../../api/hooks/uploadVideos/useUploadVideosRequests';

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.25rem;
  margin-bottom: 1.5625rem;
  background-color: ${Colors.Gray[200]};
`;

const ReportTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 0 0 2rem 0;
`;

const ReportContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  ${(props) =>
    props.disabled
      ? `opacity: 0.5;
  filter: grayscale(90%);
  pointer-events: none;`
      : ''}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkHeaderContainer = styled.div`
  max-width: 75%;
`;

export const FullWidthContainer = styled.div`
  width: 100%;

  & > * {
    margin-top: 1rem;
  }
  & :first-child {
    margin-top: 0;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & :first-child {
    padding-left: 0;
  }
`;

const GOOGLE_DRIVE_REGEX = /^https:\/\/drive\.google\.com\/.+$/;
const S3_NETWORK_LOGOS_URL = 'https://replai-public-assets.s3.amazonaws.com/network-logos';

const UPLOAD_SOURCES = [
  {
    type: 'GOOGLE_DRIVE',
    title: 'Google Drive',
    route: 'google-drive',
    enabled: true,
    image: `${S3_NETWORK_LOGOS_URL}/google_drive.png`,
  },
  {
    type: 'FACEBOOK',
    title: 'Facebook',
    route: 'facebook',
    enabled: false,
    image: `${S3_NETWORK_LOGOS_URL}/facebook.png`,
  },
  {
    type: 'YOUTUBE',
    title: 'YouTube',
    enabled: false,
    image: `${S3_NETWORK_LOGOS_URL}/youtube.png`,
  },
];

type UploadStatus = {
  label: string;
  message: string;
  color: NotificationProps['color'];
};

const Reports = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const uploadVideosMutation = useUploadVideosRequests(projectId);
  const [sourceLink, setSourceLink] = useState<string | null>(null);
  const [sourceLinkError, setSourceLinkError] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null);

  const onSubmit = async () => {
    if (sourceLinkError == null && sourceLink != null) {
      await uploadVideosMutation.mutateAsync(
        { source: 'GOOGLE_DRIVE', sourceLink },
        {
          onSuccess: () => {
            setUploadStatus({
              label: 'Uploading',
              message: 'Your videos are being uploaded. This may take a few minutes.',
              color: 'Success',
            });
          },
          onError: () => {
            setUploadStatus({
              label: 'Failed',
              message: 'Failed to upload assets. Ensure you provide a valid Google Drive folder link.',
              color: 'Error',
            });
          },
          onSettled: () => {},
        }
      );
    }
  };

  return (
    <>
      <TopNavPageTitle
        title="Upload Videos"
        subtitle="Upload your videos to your Facebook Ad Account."
        eventPrefix="upload-videos"
      />
      <Divider />
      <div>
        <SectionHeader title="Videos Source" subtitle="Choose one of our available sources." withDivider={false} />
        <ReportTypesContainer>
          {UPLOAD_SOURCES.map((source) => (
            <ReportContainer disabled={!source.enabled}>
              <VideoCard
                isActive={source.enabled}
                isStaticImage
                name={source.title}
                previewUrl={source.image}
                disableClick
                previewLoading={false}
                previewBackgroundColor={Colors.Common.White}
                showMetrics={false}
                recommendationNumber={0}
                fixedWidth
                thumbnailHeightRem={9}
              />
            </ReportContainer>
          ))}
        </ReportTypesContainer>
      </div>
      <LinkContainer>
        <LinkHeaderContainer>
          <Typography style={{ marginBottom: 0 }} fontWeight="semi-bold">
            Source Link
          </Typography>
          <Typography type="text-sm">
            Paste the link to your videos from the source you selected. We will take care of the rest.
          </Typography>
        </LinkHeaderContainer>
        <FullWidthContainer>
          <Input
            key="video-source-link"
            onChange={(e) => {
              setSourceLink(e.target.value);
              if (e.target.value.length > 0) {
                setSourceLinkError(
                  GOOGLE_DRIVE_REGEX.test(e.target.value) ? null : 'Please provide a valid Google Drive folder link'
                );
              }
            }}
            error={sourceLinkError != null}
            hintText={sourceLinkError ?? 'Ensure the folder is shared with replai@replai.io (viewer access is enough).'}
            fullWidth
            placeholder="https://drive.google.com/..."
            leadingIcon="Link"
          />
          <UploadButtonContainer>
            <Button
              variant="regular"
              onClick={onSubmit}
              leadingIcon={{ name: 'Upload' }}
              disabled={sourceLinkError != null || sourceLink == null}
              style={{ justifyContent: 'center', width: '100%', paddingRight: 0 }}
            >
              Upload
            </Button>
          </UploadButtonContainer>
          {uploadStatus && (
            <Notification
              badgeLabel={uploadStatus.label}
              badgeTitle="Status"
              color={uploadStatus.color}
              message={uploadStatus.message}
            />
          )}
        </FullWidthContainer>
      </LinkContainer>
    </>
  );
};

export default Reports;
